<!--  -->
<template>
  <div class="buyBooks">
    <div class="head_NavBar">
      <div class="title">精选推荐</div>
    </div>
    <van-search
      v-model="getData.title"
      shape="round"
      background="#fff"
      placeholder="您想要查找的书"
      @search="onSearch"
      @clear="onClear"
    />
    <van-sticky>
      <div class="tab_cont">
        <div
          class="tab_box"
          v-for="(item, index) in tabList"
          :key="index"
          :class="tabIndex == index + 1 ? 'arr' : ''"
          @click="tabFun(index)"
        >
          {{ item.title }}
        </div>
      </div>
    </van-sticky>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-empty v-if="listNO" description="没有书籍" />

        <ul class="shuji_list">
          <li
            v-for="(item, index) in boosList"
            :key="index"
            @click="DetailsFun(item)"
          >
            <div class="shuji_list_box">
              <div class="tu_box">
                <img :src="'http://tfsz.symansbon.cn/'+item.showImg" alt="" srcset="" />
              </div>
              <div class="title_box">
                <div class="title">{{ item.tittle }}</div>
                <div class="tuijian">
                  推荐率
                  <van-rate
                    v-model="item.number"
                    allow-half
                    void-icon="star"
                    size="11"
                    color="#ED6C00"
                    void-color="#eee"
                    readonly
                  />
                </div>
                <div class="chuban">出版社：{{ item.publish }}</div>
              </div>
            </div>
          </li>
        </ul>
      </van-list>
    </van-pull-refresh>
    <van-overlay :show="Detailsshow" :lock-scroll="false">
      <div class="wrapper">
        <div class="block">
          <div class="tianchuang_box">
            <div class="up_box">
              <div class="tu_box">
                <img :src="'http://www.tfbookfair.com/'+tanData.showImg" alt="" srcset="" />
              </div>
              <div class="title_box">
                <div class="title">{{ tanData.tittle }}</div>
                <div class="tuijian">
                  推荐率
                  <van-rate
                    v-model="tanData.number"
                    allow-half
                    void-icon="star"
                    size="11"
                    color="#ED6C00"
                    void-color="#eee"
                    readonly
                  />
                </div>
                <div class="chuban">出版社：{{ tanData.publish }}</div>
                <div class="diangjia">
                  定价:<span class="fu">￥</span
                  ><span class="money">{{ tanData.price }}</span>
                </div>
              </div>
            </div>
            <p class="text_box" v-html="tanData.content">
              <!-- {{ tanData.content }} -->
            </p>
          </div>
        </div>
        <img class="off" src="../../images/ins/off.png" @click="offDetail" />
      </div>
    </van-overlay>
    <navBer :navNum="4"></navBer>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  Sticky,
  List,
  Cell,
  PullRefresh,
  Search,
  Toast,
  Empty,
  Rate,
  Overlay,
} from "vant";
const url='https://tfbookfair2022.symansbon.cn';
export default {
  //import引入的组件需要注入到对象中才能使用
  name: "buyBooks",
  components: {
    [Sticky.name]: Sticky,
    [List.name]: List,
    [Cell.name]: Cell,
    [PullRefresh.name]: PullRefresh,
    [Search.name]: Search,
    [Toast.name]: Toast,
    [Empty.name]: Empty,
    [Rate.name]: Rate,
    [Overlay.name]: Overlay,
  },
  data() {
    //这里存放数据
    return {
      tabList: [
        {
          title: "二十大图书专栏",
        },
        {
          title: "精品图书",
        },
        {
          title: "精品文创",
        },
      ],
      shuji_list: [
        {
          imgUrl: "",
          title: "奔向共同富裕·中国式现代化",
          rate: 3.5,
          publish: "浙江人民出版社",
          money: "120",
          text: "一部关于中国古代政治制度史的力作，选取16个历史横断面，着重探讨每一个封建王朝盛世背后，百姓生活的历史真相。作者直指封建王朝的核心理念“外儒内法”，将秦制帝国纷繁复杂的历史常态化、规律化，探索帝国运转的内在理路，揭示其延绵长达两千余年的奥秘；深入每个截点，解读信史文献，用严密逻辑揭开王朝治世的面纱。",
        },
        {
          imgUrl: "",
          title: "人民的信仰",
          rate: 3.5,
          publish: "浙江人民出版社",
          money: "150",
          text: "一部关于中国古代政治制度史的力作，选取16个历史横断面，着重探讨每一个封建王朝盛世背后，百姓生活的历史真相。作者直指封建王朝的核心理念“外儒内法”，将秦制帝国纷繁复杂的历史常态化、规律化，探索帝国运转的内在理路，揭示其延绵长达两千余年的奥秘；深入每个截点，解读信史文献，用严密逻辑揭开王朝治世的面纱。",
        },
        {
          imgUrl: "",
          title: "共同富裕的中国方案",
          rate: 4,
          publish: "浙江人民出版社",
          money: "110",
          text: "一部关于中国古代政治制度史的力作，选取16个历史横断面，着重探讨每一个封建王朝盛世背后，百姓生活的历史真相。作者直指封建王朝的核心理念“外儒内法”，将秦制帝国纷繁复杂的历史常态化、规律化，探索帝国运转的内在理路，揭示其延绵长达两千余年的奥秘；深入每个截点，解读信史文献，用严密逻辑揭开王朝治世的面纱。",
        },
        {
          imgUrl: "",
          title: "奔向共同富裕·中国式现代化",
          rate: 3.5,
          publish: "浙江人民出版社",
          money: "80",
          text: "一部关于中国古代政治制度史的力作，选取16个历史横断面，着重探讨每一个封建王朝盛世背后，百姓生活的历史真相。作者直指封建王朝的核心理念“外儒内法”，将秦制帝国纷繁复杂的历史常态化、规律化，探索帝国运转的内在理路，揭示其延绵长达两千余年的奥秘；深入每个截点，解读信史文献，用严密逻辑揭开王朝治世的面纱。",
        },
        {
          imgUrl: "",
          title: "奔向共同富裕·中国式现代化",
          rate: 3.5,
          publish: "浙江人民出版社",
          money: "120",
          text: "一部关于中国古代政治制度史的力作，选取16个历史横断面，着重探讨每一个封建王朝盛世背后，百姓生活的历史真相。作者直指封建王朝的核心理念“外儒内法”，将秦制帝国纷繁复杂的历史常态化、规律化，探索帝国运转的内在理路，揭示其延绵长达两千余年的奥秘；深入每个截点，解读信史文献，用严密逻辑揭开王朝治世的面纱。",
        },
        {
          imgUrl: "",
          title: "奔向共同富裕·中国式现代化",
          rate: 3.5,
          publish: "浙江人民出版社",
          money: "120",
          text: "一部关于中国古代政治制度史的力作，选取16个历史横断面，着重探讨每一个封建王朝盛世背后，百姓生活的历史真相。作者直指封建王朝的核心理念“外儒内法”，将秦制帝国纷繁复杂的历史常态化、规律化，探索帝国运转的内在理路，揭示其延绵长达两千余年的奥秘；深入每个截点，解读信史文献，用严密逻辑揭开王朝治世的面纱。",
        },
        {
          imgUrl: "",
          title: "奔向共同富裕·中国式现代化",
          rate: 3.5,
          publish: "浙江人民出版社",
          money: "120",
          text: "一部关于中国古代政治制度史的力作，选取16个历史横断面，着重探讨每一个封建王朝盛世背后，百姓生活的历史真相。作者直指封建王朝的核心理念“外儒内法”，将秦制帝国纷繁复杂的历史常态化、规律化，探索帝国运转的内在理路，揭示其延绵长达两千余年的奥秘；深入每个截点，解读信史文献，用严密逻辑揭开王朝治世的面纱。",
        },
      ],
      tabIndex: 1,
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      searchValue: "", //搜索词
      listNO: false,
      revalue: 3.5,
      Detailsshow: false, //详情弹窗
      tanData: {
        imgUrl: "",
        title: "",
        rate: null,
        publish: "",
        money: "",
        text: "",
      },
      contPage: 0,
      getData: {
        type: 14,
        title: "",
        size: 10,
        page: 1,
      },
      boosList: [],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    onSearch(val) {
      //搜索书籍
      this.onRefresh();
      this.onLoad();
    },
    onClear() {
      // Toast(1);
      // this.searchValue = ''
    },
    tabFun(index) {
      
      this.tabIndex = index + 1;
      //console.log('chuan',index)
      switch (index) {
        case 0:
          this.getData.type = 14;
          break;
        case 1:
          this.getData.type = 15;
          break;
        case 2:
          this.getData.type = 16;
          break;
        default:
          break;
      }

      this.boosList = [];
      this.getData.page = 1;
      this.onLoad();
    },
    onLoad() {
      let that = this;
      this.getRequest(
        `${url}/api/Book`,
        that.getData
      ).then((res) => {
        let newList = that.boosList.concat(res.books);
        that.boosList = newList;
        //console.log(that.boosList);
        if (that.getData.page == res.allIndex) {
          this.finished = true;
        } else {
          that.getData.page++;
        }
        this.loading = false;
        if (res.books.length == 0) {
          this.listNO = true;
        }

        this.refreshing = false;
        //console.log("状态", this.loading);
      });

      // setTimeout(() => {
      //   if (this.refreshing) {
      //     this.list = [];
      //     this.refreshing = false;
      //   }

      //   for (let i = 0; i < 10; i++) {
      //     this.list.push(this.list.length + 1);
      //   }
      //   this.loading = false;

      //   if (this.list.length >= 40) {
      //     this.finished = true;
      //   }
      //   if (this.list.length == 0) {
      //     this.listNO = true;
      //   }
      // }, 1000);
      // //console.log(this.list.length);

      // this.loading = false;
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.boosList = [];
      this.getData.page = 1;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    DetailsFun(item) {
      this.tanData = item;
      this.Detailsshow = true;
    },
    offDetail() {
      this.Detailsshow = false;
    },
    getBookLIst() {},
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    if(this.$route.query.tab){
     
      this.tabFun(Number(this.$route.query.tab))
      //console.log(this.$route.query.tab,this.tabIndex);
    }
   
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    // this.getBookLIst();
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped lang="less">
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.block {
  width: 330px;
  height: 466px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 10px 10px 10px 10px;
  margin-bottom: 17px;
  padding: 30px 15px 40px 15px;
}
.tianchuang_box {
  .up_box {
    display: flex;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(112, 112, 112, 0.377);
    .tu_box {
      width: 125px;
      height: 166px;
      margin-right: 30px;
      img {
        width: 100%;
        max-height: 100%;
      }
    }
    .title_box {
      width: 165px;
      .title {
        font-size: 15px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #333333;
        line-height: 1.4;
        height: 80px;
      }
      .tuijian {
        font-size: 12px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #7e7e7e;
        margin-bottom: 12px;
      }
      .chuban {
        font-size: 12px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #7e7e7e;
        margin-bottom: 15px;
      }
      .diangjia {
        font-size: 12px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #7e7e7e;
        .fu {
          font-size: 10px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #ef1e1c;
        }
        .money {
          font-size: 14px;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: bold;
          color: #ef1e1c;
        }
      }
    }
  }
  .text_box {
    width: 100%;
    height: 160px;
    margin-top: 30px;
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #555555;
    line-height: 1.8;
    overflow-x: scroll;
  }
}
.off {
  width: 35px;
  height: 35px;
}
.buyBooks {
  padding-bottom: 80px;
}
.tab_cont {
  position: relative;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-around;
  background-color: #fff;
  .tab_box {
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    line-height: 40px;
    color: #333333;
  }
  .arr {
    color: #2a599f;
  }
}
.shuji_list {
  position: relative;
  padding: 20px 27px;
  li {
    margin-bottom: 10px;
  }
}
.shuji_list_box {
  position: relative;
  width: 100%;
  //   height: 160px;
  box-shadow: 0px 5px 15px 1px rgba(42, 89, 159, 0.05);
  border-radius: 6px 6px 6px 6px;
  padding: 20px 10px;
  display: flex;
  .tu_box {
    width: 90px;
    height: 120px;
    margin-right: 30px;
    img {
      width: 100%;
      max-height: 100%;
    }
  }
  .title_box {
    width: 165px;
    .title {
      font-size: 15px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      line-height: 1.4;
      height: 80px;
    }
    .tuijian {
      font-size: 12px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #7e7e7e;
      margin-bottom: 12px;
    }
    .chuban {
      font-size: 12px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #7e7e7e;
    }
  }
}
</style>